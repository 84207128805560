(function () {
  'use strict';

  $.fn.smoothScroll = function(options) {
    options = $.extend({
      offset: 0,
      duration: 500
    }, options);

    return this.each(function() {
      $(this).on('click', function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop:$(this.hash).offset().top - options.offset}, options.duration);
      });
    });
  };

  function ContactForm(id) {
    this.$el = $(id);
    if (this.$el.length < 1) {
      return;
    }

    var prefix = 'contact';
    this.$el.on('submit', this.onSubmit.bind(this));
    this.$content = this.$el.find('.' + prefix + '-content');
    this.$confirmation = this.$el.find('.' + prefix + '-confirmation');
    this.$error = this.$el.find('.' + prefix + '-error');
    this.$el.find('input').eq(0).focus();
  }

  ContactForm.prototype.onSubmit = function(evt) {
    function blur() {
      $(evt.target).find('[type="submit"]').blur();
    }

    $.post(this.$el.attr('action'), this.$el.serialize()).then(function (data) {
      var ticket = data ? '(Ticket #' + data + ')' : '';
      blur();
      this.$error.hide();
      this.$el.find(':input').prop('disabled', true);
      this.$content.find('[type=submit]').css('visibility', 'hidden');
      this.$content.css('opacity', '.5');
      this.$confirmation.show();
      this.$confirmation.find('.ticket').text(ticket).css('opacity', '.7');
    }.bind(this), function(res) {
      blur();
      this.$error.show();
      this.$error.find('.status').text(res.status);
      this.$error.find('.error').text(res.responseText);
    }.bind(this));

    return false;
  }

  function toggleBelowFold() {
    var onScroll = function() {
      $('body').toggleClass('scroll', $(window).scrollTop() > 100);
    }

    $(window).on('scroll', onScroll);
    onScroll();
  }

  function scrollSpy() {
    var target = 'ul.header-nav-sub:visible';
    var $spy = $('body').scrollspy({target: target, offset: 260});
    $spy.data('bs.scrollspy').selector = target + ' > li > a';
    $spy.scrollspy('refresh');
    $spy.scrollspy('process');
  }

  function smoothScroll() {
    $('ul.header-nav-sub a[href^="#"]').smoothScroll({offset: 250});
  }

  function rebox() {
    $('.lightbox-thumbs').rebox({selector: 'figure a'});
  }

  new ContactForm('#contact-form');
  toggleBelowFold();
  scrollSpy();
  smoothScroll();
  rebox();
})();
